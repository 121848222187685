import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@material-ui/core";
import API from "../../API";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: 16,
        minWidth: 450
    },
    content: {
        minWidth: 400
    }
}));

const DeviceActivationDialog = ({onClose}) => {
    const classes = useStyles()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [error, setError] = useState('')

    const createDevice = (name) => {
        setLoading(true)
        API.fetch(API.DEVICE_CREATE, {name})
            .then(r => {
                console.log('[STATUS]', r)
                if (r.status === 'OK') {
                    sendSeed(r)
                } else {
                    setLoading(false)
                    setError(r.error)
                }
            })
            .catch(e => {
                setLoading(false)
                // setError(e)
                console.log('[ERROR]', e)
            })
    }

    const sendSeed = (r) => {
        setLoading(true)
        if (window.isElectron) {
            window.ipcRenderer.sendToHost('ipc-message', JSON.stringify(r))
            window.ipcRenderer.on('ipc-message', (ipcResp, data) => {
                console.log('[WINDOW IPC]', ipcResp, data)
                if (data.error) {
                    console.error('[]', data.error)
                    setError(data.error)
                } else {
                    onClose()
                }
                setLoading(false)
            })
        }
    }

    const content = loading ?
            <CircularProgress/> :
            <TextField fullWidth
                       label="Имя устройства"
                       placeholder='Например "Интерактивный стол в классе"'
                       onChange={e => setName(e.target.value)}/>

    return <Dialog open={true} onClose={onClose} title="Активация нового устройства">
        <DialogTitle>Активация нового устройства</DialogTitle>
        <DialogContent className={classes.content}>
            {content}
            {error && <Typography color='primary'>{error}</Typography>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Отмена</Button>
            <Button color='primary' onClick={() => createDevice(name)}>Создать</Button>
        </DialogActions>
    </Dialog>
}

export default DeviceActivationDialog