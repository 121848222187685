import {action, computed, makeObservable, observable} from "mobx"
import API from "../API";
import Device from "./Device";


export default class User {

    loading = true

    pk = ''
    email = ''
    firstName = ''
    lastName = ''
    companyName = ''
    city = ''
    photo = ''
    confirmed = ''
    is_staff = false
    devices = []

    constructor() {
        makeObservable(this, {
            pk: observable,
            email: observable,
            firstName: observable,
            lastName: observable,
            companyName: observable,
            city: observable,
            devices: observable,
            photo: observable,
            confirmed: observable,
            is_staff: observable,

            loading: observable,

            save: action,
            load: action,
            login: action,
            register: action,

            authorized: computed
        })
        this.load();
    }

    load = () => {
        this.loading = true
        API.fetch(API.USER)
            .then(r => {
                this.loading = false
                if (!r.pk) {
                    return
                }
                this.pk = r.pk
                this.email = r.email
                this.firstName = r.first_name
                this.lastName = r.last_name
                this.companyName = r.company_name
                this.city = r.city
                this.photo = r.photo
                this.confirmed = r.confirmed
                this.is_staff = r.is_staff
                this.devices = r.devices.map(d => new Device(d))
                return this
            })
            .catch(r => {
                this.loading = false
                return r
            })
    }

    save = () => {
        this.loading = true
        return API.fetch(API.USER_SAVE, {
            first_name: this.firstName,
            last_name: this.lastName,
            company_name: this.companyName,
            city: this.city,
        })
            .then((r) => {
                this.loading = false
            })
    }

    savePhoto = (photo) => {
        this.loading = true
        return API.fetch(API.USER_SAVE_PHOTO, {photo})
            .then((r) => {
                this.loading = false
            })
    }

    login = (email, password, captcha) => {
        this.loading = true
        return API.fetch(API.AUTH_SIGN_IN, {email, password, captcha})
            .then((r) => {
                this.loading = false

                if (r.non_field_errors) {
                    throw r.non_field_errors[0]
                }
                if (r.captcha) {
                    throw 'Необходимо решить капчу'
                }

                return this.load()
            })
    }

    register = (email, password1, password2, first_name, last_name, company_name, captcha) => {
        this.loading = true

        return API.fetch(API.AUTH_SIGN_UP, {
            email, password1, password2, first_name, last_name, company_name, captcha
        })
            .then(r => {
                this.loading = false

                if (r.password1 || r.password2) {
                    throw 'проверьте правильность пароля\nон должен содержать не меньше 8 знаков, цифры и символы'
                }
                if (r.email) {
                    throw 'указанный пользователь уже существует'
                }
                if (r.captcha) {
                    throw 'Необходимо решить капчу'
                }
                if (r.non_field_errors) {
                    throw r.non_field_errors[0]
                }

                this.load()
            })
    }

    logout = () => API.fetch(API.AUTH_SIGN_OUT)
        .then(() => {
            this.pk = null
            this.email = ''
            this.firstName = ''
            this.lastName = ''
            this.companyName = ''
            this.city = ''
            this.confirmed = ''
            this.photo = null
            this.devices = []
            this.is_staff = false
        })

    get authorized() {
        return !!this.pk
    }
}
