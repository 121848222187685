import React, {createRef, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../components/Copyright";
import API from "../API";
import Captcha from "../components/Captcha";
import {inject, observer} from "mobx-react";
import Form from "../components/Form";
import {useHistory} from "react-router";
import {Divider, IconButton} from "@material-ui/core";
import {FcGoogle, GrFacebook, SiFacebook} from "react-icons/all";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = createRef()

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    or: {
        position: 'relative',
        top: '-12px',
        textAlign: 'center'
    },
    orText: {
        maxWidth: '50px',
        background: '#fafafa',
        display: 'block',
        margin: '0 auto',
        color: '#7a7a7a'
    },
    social: {
        display: 'flex',
        justifyContent: 'center'
    }
}));


const SignIn = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [captcha, setCaptcha] = useState('')
    const [error, setError] = useState('')

    useEffect(() => userStore.authorized && history.push('/'), [userStore.authorized])

    const login = () => {
        userStore.login(email, password, captcha)
            .catch(e => {
                setError(e)
                recaptchaRef.current.reset()
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Вход в личный кабинет
                </Typography>
                <Form className={classes.form} noValidate onSubmit={() => {}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Почтовый адрес"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcQRoMaAAAAALwiHqTZ0gHlJFcsMOH85Wl_PJLa"
                        onChange={setCaptcha}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Запомнить меня"
                    />

                    {error && <Box m={1}>
                        <Typography className={classes.error} color="error">{error}</Typography>
                    </Box>}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={login}
                    >
                        Войти
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/restore-password" variant="body2" onClick={e => {
                                e.preventDefault()
                                history.push('/restore-password')
                            }}>
                                Забыли пароль?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="sign-up" variant="body2" onClick={e => {
                                e.preventDefault()
                                history.push('/sign-up')
                            }}>
                                {"Создать аккаунт"}
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </div>

            <Box mt={6} mb={4}>
                <Divider/>
                <div className={classes.or}>
                    <div className={classes.orText}>или</div>
                </div>
            </Box>

            <div className={classes.social}>
                <IconButton>
                    <FcGoogle style={{width: 42, height: 42}}/>
                </IconButton>
                <IconButton>
                    <SiFacebook style={{width: 42, height: 42, color: '#3b5998'}}/>
                </IconButton>
            </div>

            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default inject('userStore')(observer(SignIn))