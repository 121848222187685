import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 16
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#7a7a7a'
    },
    avatar: {
        width: 96,
        height: 96
    }
}));

const Profile = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const handleFileChange = ({target}) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            userStore.savePhoto(e.target.result)
                .then(r => userStore.load())
        };
    }

    const handleSave = () => {
        userStore.save()
    }

    return <div className={classes.root}>

        <Container maxWidth="sm">

            <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar} alt={userStore.firstName || 'Анонимный пользователь'} src={userStore.photo} />
                <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="" component="span" className={classes.button}>
                        Изменить фотографию
                    </Button>
                </label>
            </div>

            <TextField
                margin="normal"
                fullWidth
                label="Почтовый адрес"
                name="email"
                autoComplete="email"
                value={userStore.email}
                disabled
            />

            <TextField
                margin="normal"
                fullWidth
                label="Имя"
                name="firstName"
                autoComplete="firstName"
                value={userStore.firstName}
                onChange={e => userStore.firstName = e.target.value}
            />

            <TextField
                margin="normal"
                fullWidth
                label="Фамилия"
                name="lastName"
                autoComplete="lastName"
                value={userStore.lastName}
                onChange={e => userStore.lastName = e.target.value}
            />

            <TextField
                margin="normal"
                fullWidth
                label="Компания"
                name="companyName"
                autoComplete="companyName"
                value={userStore.companyName}
                onChange={e => userStore.companyName = e.target.value}
            />

            <TextField
                margin="normal"
                fullWidth
                label="Город"
                name="city"
                autoComplete="city"
                value={userStore.city}
                onChange={e => userStore.city = e.target.value}
            />

            <Box mt={6}>
                <Button onClick={handleSave}>Сохранить</Button>
            </Box>

        </Container>

    </div>

}
export default inject('userStore')(observer(Profile))