import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserStore from "./stores/User";
import {Provider as MobxProvider} from "mobx-react";
import {ThemeProvider} from "@material-ui/core/styles";



const stores = {
    userStore: new UserStore()
}

ReactDOM.render(
  <React.StrictMode>
      <MobxProvider {...stores}>
          <App />
      </MobxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

