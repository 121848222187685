import React, {createRef, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../components/Copyright";
import Captcha from "../components/Captcha";
import {inject, observer} from "mobx-react";
import API from "../API";
import {useHistory} from "react-router";
import Form from "../components/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const recaptchaRef = createRef()

const SignUp = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [city, setCity] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [captcha, setCaptcha] = useState('')

    const [error, setError] = useState('')

    useEffect(() => userStore.authorized && history.push('/'), [userStore.authorized])

    const signUp = () => {
        if (password !== repeatPassword) {
            setError('Пароли не совпадают')
        }

        userStore.register(email, password, repeatPassword, firstName, lastName, companyName, city, captcha)
            .then(r => {
                history.push('/')
            })
            .catch(e => {
                setError(e)
                recaptchaRef.current.reset()
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Регистрация аккаунта
                </Typography>
                <Form className={classes.form} noValidate onSubmit={() => {}}>

                    <Typography variant="body2" align="left">
                        Информация о себе
                    </Typography>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="Имя"
                        name="firstName"
                        autoComplete="firstName"
                        autoFocus
                        onChange={e => setFirstName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="lastName"
                        label="Фамилия"
                        name="lastName"
                        autoComplete="lastName"
                        onChange={e => setLastName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="companyName"
                        label="Компания"
                        name="companyName"
                        autoComplete="companyName"
                        onChange={e => setCompanyName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="city"
                        label="Город"
                        name="city"
                        autoComplete="city"
                        onChange={e => setCity(e.target.value)}
                    />

                    <br/>
                    <br/>

                    <Typography variant="body2" align="left">
                        Авторизационные данные
                    </Typography>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="repeatPassword"
                        label="Повторите пароль"
                        type="password"
                        id="repeatPassword"
                        onChange={e => setRepeatPassword(e.target.value)}
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcQRoMaAAAAALwiHqTZ0gHlJFcsMOH85Wl_PJLa"
                        onChange={setCaptcha}
                    />

                    {error && <Box m={1}>
                        <Typography className={classes.error} color="error">{error}</Typography>
                    </Box>}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={signUp}
                    >
                        Зарегистрироваться
                    </Button>

                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Link href="/sign-in" variant="body2" onClick={e => {
                                e.preventDefault()
                                history.push('/sign-in')
                            }}>
                                Уже есть аккаунт? Войти
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default inject('userStore')(observer(SignUp))