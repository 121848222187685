import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import Avatar from "@material-ui/core/Avatar";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CircularProgress,
    FormControlLabel,
    Switch,
    TextField
} from "@material-ui/core";
import TablePanel from "../../components/TablePanel";
import IconBack from "@material-ui/icons/ArrowBack"
import IconSave from "@material-ui/icons/Save"
import Utils from "../../Utils";
import Box from "@material-ui/core/Box";
import API from "../../API";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 16
    },
    flex1: {
        flex: 1
    },
    userInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 auto'
    },
    field: {
        display: 'block',
        marginBottom: 10
    },
    input: {
        minWidth: 300
    }
}));





export default ({user, onClose}) => {
    const classes = useStyles()
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [email,  setEmail] = useState(user.email || '')
    const [first_name,  setFirstName] = useState(user.first_name || '')
    const [last_name,  setLastName] = useState(user.last_name || '')
    const [company_name,  setCompanyName] = useState(user.company_name || '')
    const [city,  setCity] = useState(user.city || '')
    const [confirmed,  setConfirmed] = useState(user.confirmed || false)
    const [device_limit,  setDeviceLimit] = useState(user.device_limit || 0)

    const handleSave = () => {
        setLoading(true)
        API.fetch(API.ADMIN_USER_SAVE, {
            pk: user.pk, first_name, last_name, company_name, city, confirmed, device_limit
        })
            .then(r => {
                setLoading(false)
                setError(r.error || 'изменения сохранены')
            })
    }

    const getHardware = (id, hw) => {
        try {
            hw = JSON.parse(hw.replaceAll("'",'"').replaceAll('None', 'null'))
            return <div>
                {Object.entries(hw).map(([k,v]) => <div key={id + k}>{k}: {v}</div>)}
            </div>
        } catch (e) {
            console.error('[JSON PARSE]', hw)
            return hw
        }
    }

    return <div>

        <Card className={classes.root}>

            <CardActions>
                <Button color='primary' startIcon={<IconBack/>} onClick={() => onClose(!!error)}>Назад</Button>
                <div className={classes.flex1}/>
                {error && <Typography variant='body2'>{error}</Typography>}
                <Button color='primary' startIcon={loading ? <CircularProgress size={16}/> : <IconSave/>} onClick={handleSave}>Сохранить</Button>
            </CardActions>

            <CardContent>

                <div className={classes.userInfo}>
                    <Box mr={8}>
                        <Avatar src={user.photo}/>
                    </Box>
                    <Box mr={8}>
                        {[
                            {label: 'Email', value: email, action: setEmail, disabled: true},
                            {label: 'Имя', value: first_name, action: setFirstName},
                            {label: 'Фамилия', value: last_name, action: setLastName},
                            {label: 'Компания', value: company_name, action: setCompanyName},
                            {label: 'Город', value: city, action: setCity},
                        ].map(({label, value, action, disabled}) =>
                            <TextField {...{label, value, disabled}}
                                       className={classes.field} InputProps={{className:classes.input}}
                                       onChange={({target}) => action(target.value)} />)}
                    </Box>
                    <div>
                        <FormControlLabel label="Пользователь подтвержден" control={
                                <Switch
                                    checked={confirmed}
                                    onChange={({target: {checked}}) => setConfirmed(checked)}
                                    name="confirmed"
                                    color="primary"
                                />
                            }/><br/>
                        <TextField label="Лимит устройств" value={device_limit}
                                   onChange={({target}) => setDeviceLimit(target.value)}/><br/>
                    </div>
                </div>


            </CardContent>

        </Card>

        <Card className={classes.root}>
            <CardHeader title='Устройства'/>
            <TablePanel
                hideToolbar
                size="small"
                showAll
                rowHoverable={false}
                data={user.devices}
                config={[
                    {name: 'Имя', field: 'name'},
                    {name: 'Последний ping', field: ({last_ping}) => Utils.formatDateT(last_ping.date)},
                    {name: 'Версия', field: ({last_ping:{ping}}) => ping.env_version},
                    {name: 'Железо', field: ({id, last_ping:{ping}}) => getHardware(id, ping.hardware)},
                    {name: 'Ip', field: ({last_ping:{ping}}) => ping.ip},
                    {name: 'ОС', field: ({last_ping:{ping}}) => ping.os_version},
                    {name: 'User Agent', field: ({last_ping:{ping}}) => ping.user_agent},
                ]}/>
        </Card>


    </div>


}
