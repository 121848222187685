import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";


const AlertDialog = ({title, text, onClose, onSubmit}) =>
    <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Отмена
            </Button>
            <Button onClick={onSubmit} color="primary" autoFocus>
                Подтвердить
            </Button>
        </DialogActions>
    </Dialog>

export default AlertDialog