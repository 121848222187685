import React from 'react';
import {
    CardHeader,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";


import Utils from "../Utils";
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import IconArrowUp from '@material-ui/icons/ArrowDropUp'
import IconArrowDown from '@material-ui/icons/ArrowDropDown'
import Typography from "@material-ui/core/Typography";

const black = common.black;
const grey500 = grey['500'];

export default class TablePanel extends React.Component {

    static defaultStyles = {
        fontSize: '12px'
    };

    static cellStyleWide = {
        overflow: 'initial',
        textOverflow: 'initial',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    };

    static cellStyleCenter = {
        textAlign: 'center'
    };

    state = {
        currentPage: 1,
        rowsOnPage: 10,
        sortField: '',
        sortDesc: false
    };

    static defaultProps = {
        data: [],
        rowHoverable: true
    };

    componentWillMount(){
        let {showAll} = this.props;
        showAll && this.setState({showAll})
    }

    componentWillReceiveProps(newProps) {
        let {rowsOnPage} = this.props;

        if (rowsOnPage !== newProps.rowsOnPage) {
            this.setState({rowsOnPage: newProps.rowsOnPage})
        }
    }

    onHeaderClick(fieldConfig) {
        if (fieldConfig.sortField || (typeof fieldConfig.field === 'string')) {
            this.setState({sortField: fieldConfig.sortField || fieldConfig.field, sortDesc: !this.state.sortDesc})
        }
    }

    getToolbar = () => this.props.toolbar ||
            <CardHeader title={this.props.toolbarTitle || <Typography variant='body2'>{this.props.toolbarText}</Typography>}
                        subheader={this.props.toolbarSubTitle || <Typography variant='body2'>count <b color={black}>{this.props.data.length}</b></Typography>}/>;

    onPageNumberChange = (currentPage) =>
        this.setState({currentPage},
            () => this.props.onPageNumberChange && this.props.onPageNumberChange(currentPage));

    onRowsOnPageChange = (rowsOnPage, currentPage) =>
        this.setState({currentPage},
            () => this.props.onRowsOnPageChange && this.props.onRowsOnPageChange(rowsOnPage, currentPage));

    render() {
        let {data, activeFilters, hideToolbar, onRowClick, onCellClick, size, isLoading, hidePaging, headerColumnStyle, onHeaderClick,
             rowColumnStyle, rowHoverable, config, hideHeader, tableClassName, serverPaging, tableWrapperStyle, emptyMessage, showScroll} = this.props;
        let {rowsOnPage, currentPage, sortField, showAll} = this.state;

        /**
         * Filter data
         */
        if (activeFilters && !serverPaging) {
            activeFilters.forEach(filter => {
                const fr = new RegExp(filter.filterValue);
                data = data.filter(item =>
                    fr.test(item[filter.filterName])
                )
            })
        }

        /**
         * Sort data
         */
        if (sortField) {
            data = typeof sortField === 'function'
                ? sortField(data)
                : data.sort((a,b) => Utils.sortByNumberOrString(a[sortField],b[sortField]));
            this.state.sortDesc && (data = data.reverse());
        }

        /**
         * Count of items to show in grid for local paging
         */
        if (!serverPaging) {
            let totalRowsOnPage = showAll ? 9999 : rowsOnPage;
            let to = totalRowsOnPage * currentPage;
            let from = to - totalRowsOnPage;

            data = data.slice(from, to);
        }

        const rootClasses = ["vulners-table-panel"]
        !showScroll && rootClasses.push("vulners-hide-scroll")

        return <div className={tableClassName}>

            {hideToolbar ? '' : this.getToolbar()}

            <div className={rootClasses.join(' ')} style={Object.assign({overflowX: 'scroll', scrollbarWidth: 'none'}, tableWrapperStyle)}>
                <Table size="small">

                    {!hideHeader && <TableHead>
                        <TableRow>
                            {config.map((c, i) =>
                                <TableCell key={'thead' + i}
                                           variant='head'
                                           onClick={() => onHeaderClick ? onHeaderClick(c) : this.onHeaderClick(c)}
                                           style={Object.assign({maxWidth: c.width, cursor: 'pointer'}, headerColumnStyle, c.headerStyle)}>
                                    <span className="vulners-table-sort">
                                        {c.name}
                                        {c.field !== this.state.sortField ? '' :
                                            this.state.sortDesc
                                                ? <IconArrowUp color={grey500}/>
                                                : <IconArrowDown color={grey500}/>
                                        }
                                    </span>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>}
                        <TableBody>
                            {!data.length ?
                                emptyMessage :
                                data.map((d, i) =>
                                    <TableRow key={'tbody' + i} style={{cursor: 'pointer'}}
                                              hover={rowHoverable}
                                              onClick={(e) => {
                                                  // Ignore buttons and icons on row
                                                  if (e.target.nodeName !== 'button' && e.target.nodeName !== 'svg' && e.target.nodeName !== 'path') {
                                                      onRowClick && onRowClick(d, e)
                                                  }
                                              }}>
                                        {
                                            config.map((c, j) =>
                                                <TableCell key={'tr-' + i + '-' + j}
                                                           variant='body'
                                                           style={Object.assign({maxWidth: c.width}, rowColumnStyle, c.style, this.defaultStyles)}>
                                                    {
                                                        typeof c.field === 'function' ? c.field(d) : d[c.field]
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                )
                            }
                        </TableBody>

                </Table>
            </div>

            {/*{!hidePaging && (serverPaging ?*/}
            {/*    <NumericalServerPagingBar {...this.props}/> :*/}
            {/*    <NumericalPagingBar*/}
            {/*        showAll={this.state.showAll}*/}
            {/*        rowsOnPage={rowsOnPage}*/}
            {/*        totalRows={this.props.data.totalCount || this.props.data.length}*/}
            {/*        onPageNumberChange={(currentPage) => this.setState({currentPage})}*/}
            {/*        onRowsOnPageChange={(rowsOnPage, currentPage) => this.setState({rowsOnPage, currentPage, showAll: false})}*/}
            {/*    />)}*/}

        </div>
    }
}