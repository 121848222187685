import {action, computed, makeObservable, observable} from "mobx"
import API from "../API";


export default class Device {

    loading = true

    id = ''
    ip = ''
    name = ''
    lastPing = ''
    os_version = ''
    env_version = ''
    online = false

    constructor(device) {
        makeObservable(this, {
            loading: observable,
            id: observable,
            ip: observable,
            name: observable,
            os_version: observable,
            env_version: observable,
            lastPing: observable,
            online: observable,

            save: action,
        })

        if (device) {
            this.id = device.id
            this.name = device.name
            this.ip = device?.last_ping.ping.ip
            this.lastPing = device.last_ping.date.replace(/\..+/, '').replace('T', '  ')
            this.osVersion = device?.last_ping.ping.os_version
            this.envVersion = device?.last_ping.ping.env_version
            this.online = (new Date().getTime() - new Date(device.last_ping.date).getTime()) < 60 * 60 * 1000
        }
    }

    save = () => {

    }
}
