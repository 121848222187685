import React from "react";


const Form = ({className, children, onSubmit}) => {

    return <form
        className={className}
        onKeyDown={
            (e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSubmit();
                }
            }
        }
        onSubmit={
            (e) => {
                e.preventDefault();
                e.stopPropagation();
                onSubmit();
            }
        }>
        {children}
    </form>
}

export default Form