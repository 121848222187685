import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import {Avatar, Chip, Divider} from "@material-ui/core";
import {MoreVert} from "@material-ui/icons";
import API from "../API";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    user: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Header = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget)
    const handleMenuClose = () => setAnchorEl(null)

    return <AppBar position="static">
        <Toolbar>

            <Button color="inherit"
                    onClick={() => history.push('/')}>Устройства</Button>
            <Button color="inherit"
                    onClick={() => history.push('/profile')}>Профиль</Button>
            {userStore.is_staff && <Button color="inherit"
                    onClick={() => history.push('/users')}>Пользователи</Button>}


            <Typography variant="h6" className={classes.title}>

            </Typography>
            {userStore.authorized ?
                <div className={classes.user}>
                    {userStore.email}
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenuClick}
                    >
                        {userStore.photo ? <Avatar src={userStore.photo}/> : <AccountCircle />}
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted
                          open={menuOpen} onClose={handleMenuClose}>
                        <MenuItem onClick={() => history.push('/profile')}>
                            Профиль
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={userStore.logout}>
                            Выход
                        </MenuItem>
                    </Menu>
                </div> :

                <div>
                    <Button color="inherit">Login</Button>
                </div>
            }
        </Toolbar>
    </AppBar>
}

export default inject('userStore')(observer(Header))