import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import Header from "./Header";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%'
    }
}));

const Layout = ({children}) => {
    const classes = useStyles()
    const history = useHistory()


    return <div className={classes.root}>

        <Header/>

        {children}


    </div>

}

export default Layout