import {Route, Router, Switch, useHistory} from "react-router";
import { createBrowserHistory } from "history";
import {inject, observer, Provider as MobxProvider} from "mobx-react";
import {ThemeProvider} from '@material-ui/core/styles';
import {useEffect} from "react";

import UserStore from "./stores/User";

import Layout from "./components/Layout";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import RestorePassword from "./pages/RestorePassword";
import Profile from "./pages/profile/Profile";
import Home from "./pages/devices/Index";
import Admin from "./pages/admin/Index";
import MagicTheme from "./theme/MagicTheme";

const history = createBrowserHistory();


const authPathList = ['/sign-in', '/sign-up', '/restore-password']

const App = ({userStore}) => {

  useEffect(() => {
    if (!userStore.loading) {
      let {pathname} = window.location
      if (userStore.authorized) {
        authPathList.indexOf(pathname) > 0 && history.push('/')
      } else {
        authPathList.indexOf(pathname) <= 0 && history.push('/sign-in')
      }
    }
  }, [userStore.loading, userStore.pk])

  return <ThemeProvider theme={MagicTheme}>
      <Router history={history}>
        <Switch>
          <Route exact path="/sign-in">
            <SignIn/>
          </Route>
          <Route exact path="/sign-up">
            <SignUp/>
          </Route>
          <Route exact path="/restore-password">
            <RestorePassword/>
          </Route>
          <Route exact path="/restore-password/confirm">
            <RestorePassword/>
          </Route>

          <Layout>
            <Route exact path="/profile">
              <Profile/>
            </Route>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route exact path="/users">
              <Admin/>
            </Route>
          </Layout>
        </Switch>
      </Router>
  </ThemeProvider>

}

export default inject('userStore')(observer(App))
