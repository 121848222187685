import Cookies from 'js-cookie'

const DEFAULT_HEADERS = {
    "Content-Type": 'application/json'
};

class API {

    /**
     * Send fetch
     * @param request
     * @param requestBody
     * @param rawResponse do not automatically convert response data to JSON
     * @param formData
     * @returns {*|Promise.<*>}
     */
    static fetch(request, requestBody, rawResponse, formData) {
        let url = request.url;
        let params = {
            credentials: 'same-origin',
            method: request.method || 'get',
            headers: DEFAULT_HEADERS
        };

        if (params.method === 'post') {
            if (formData) {
                let form = new FormData();
                for (let field of Object.keys(requestBody)) {
                    form.append(field, requestBody[field])
                }
                params['body'] = form;
            } else {
                params['headers']= Object.assign(DEFAULT_HEADERS, {
                    'X-CSRFToken': Cookies.get("csrftoken")
                });
                params['body'] = JSON.stringify(requestBody)
            }
        } else if (requestBody) {
            url = API.getUrlWithParams(request.url, requestBody)
        }

        return fetch(url, params)
            .then(response => API.processContent(response, rawResponse))
            .then(API.processResponse)
            .catch(API.processError)
    }

    /**
     * Check response status codes and firing errors
     * @param response
     * @param rawResponse
     */
    static processContent(response, rawResponse) {
        if (response.status === 402) {
            document.dispatchEvent(new Event('show-captcha'))
        }
        return rawResponse ? response : response.json()
    }

    /**
     * Check response status codes and firing errors
     * @param response
     */
    static processResponse(response) {
        if (typeof response == 'object'  && response.result === 'error') {
            API.processError(response.data.error)
        }

        return response
    }


    static processError(error) {
        document.dispatchEvent(new CustomEvent('show-snackbar', {detail: {message: error.message || error}}));
    }

    /**
     * Builds query string from object
     * @param url
     * @param params
     * @returns {string}
     */
    static getUrlWithParams(url, params) {
        return url + '?' + Object.keys(params)
            .reduce((a, k) => {
                a.push(k + '=' + encodeURIComponent(params[k]));
                return a
            }, []).join('&');
    }
}


API.USER = {
    method: 'get',
    url: '/api/auth/user/'
};
API.USER_SAVE = {
    method: 'post',
    url: '/api/auth/user/save/'
};
API.USER_SAVE_PHOTO = {
    method: 'post',
    url: '/api/auth/user/photo/'
};
API.USER_PASSWORD_CHANGE = {
    method: 'post',
    url: '/api/auth/password/change/'
};
API.AUTH_SIGN_IN = {
    method: 'post',
    url: '/api/auth/login/'
}
API.AUTH_SIGN_OUT = {
    method: 'post',
    url: '/api/auth/logout/'
}
API.AUTH_SIGN_UP = {
    method: 'post',
    url: '/api/auth/registration/'
}
API.AUTH_RECOVER_PASSWORD = {
    method: 'post',
    url: '/api/auth/password/reset/'
}

API.DEVICE_CREATE = {
    method: 'post',
    url: '/api/device/create/'
}
API.DEVICE_DELETE = {
    method: 'post',
    url: '/api/device/delete/'
}
API.DEVICE_EDIT = {
    method: 'post',
    url: '/api/device/edit/'
}

/**
 * Admin
 */
API.ADMIN_USERS = {
    url: '/api/admin/users/'
}

API.ADMIN_USER_SAVE = {
    method: 'post',
    url: '/api/admin/user/save/'
}




export default API;