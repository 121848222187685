import React, {createRef, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import {Box, Card, CircularProgress, Container, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Add} from "@material-ui/icons";
import Device from "./Device";
import DeviceActivationDialog from "./DeviceActivationDialog";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 16,
    },
    deviceList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    newDeviceBtn: {
        textAlign: 'center',
        marginTop: 16
    }
}));

const Index = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        console.log('[Loading]', userStore.loading, userStore.confirmed)
        if (!userStore.loading && !userStore.confirmed) {
            setTimeout(() => {
                console.log('[Loading]', userStore.loading, userStore.confirmed)
                userStore.load()
            }, 10000)
        }
    }, [userStore.loading])

    const getDeviceButton = () =>
        <div className={classes.newDeviceBtn}>
            {!userStore.devices.length && <Box m={8}>
                <Typography variant='body1'>
                    Чтобы активировать устройство, нажмите "Добавить новое устройство"
                </Typography>
            </Box>}
            {window.isElectron && <Button color='primary' variant='contained' startIcon={<Add/>} onClick={() => setDialogOpen(true)}>
                Новое устройство
            </Button>}
        </div>

    return <div>
        <Container className={classes.container}>
            <div className={classes.deviceList}>
                {userStore.devices.map(i => <Device {...i} key={i.id} onDelete={userStore.load} onEdit={userStore.load}/>)}
            </div>

            {userStore.confirmed ?
                getDeviceButton() :
                <Box m={8}>
                    <Typography variant='body1'>
                        Ваша учетная запись не подтверждена. Вы сможете зарегистрировать новое устройство после того как мы подтвердим вашу личность.
                    </Typography>
                    <div  className={classes.newDeviceBtn}>
                        <CircularProgress/>
                    </div>
                </Box>
            }

        </Container>

        {dialogOpen && <DeviceActivationDialog onClose={() => {
            setDialogOpen(false)
            userStore.load()
        }}/>}

    </div>

}
export default inject('userStore')(observer(Index))