import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import Avatar from "@material-ui/core/Avatar";
import API from "../../API";
import {Card, CircularProgress, Tooltip} from "@material-ui/core";
import TablePanel from "../../components/TablePanel";
import MoreMenuButton from "../../components/MoreMenuButton";
import IconEdit from "@material-ui/icons/Edit"
import EditUser from "./EditUser";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 16
    },
    activity: {
        width: 16,
        height: 16,
        borderRadius: 50,
        margin: '0 auto'
    }
}));



export default () => {
    const classes = useStyles()
    const history = useHistory()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [editingItem, setEditingItem] = useState(null)

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = () => {
        setLoading(true)
        API.fetch(API.ADMIN_USERS).then(r => {
            setLoading(false)
            setData(r)
        })
    }

    if (loading) {
        return <CircularProgress/>
    }

    if (editingItem) {
        return <EditUser user={editingItem} onClose={(changed) => {
            setEditingItem(null)
            loadUsers()
        }}/>
    }

    return <Card className={classes.root}>

        <TablePanel
            hideToolbar
            size="small"
            showAll
            rowHoverable={false}
            data={data}
            onRowClick={user => setEditingItem(user)}
            config={[
                {name: 'Фото',
                    field: ({photo}) => <Avatar src={photo} onClick={() => window.open(photo, '_blank')}/> },
                {name: 'Email', sortField: 'email', field: 'email'},
                {name: 'Имя', sortField: 'first_name', field: 'first_name'},
                {name: 'Фамилия', sortField: 'last_name', field: 'last_name'},
                {name: 'Компания', sortField: 'company_name', field: 'company_name'},
                {name: 'Город', sortField: 'city', field: 'city'},
                {name: 'Подтвержден', sortField: 'confirmed',
                    field: ({confirmed}) =>
                        <Tooltip title={confirmed ? 'Подтвержден' : 'Не подтвержден'}>
                            <div className={classes.activity} style={{background: confirmed ? '#00e676' : '#ff3d00'}}/>
                        </Tooltip>
                    },
                {name: 'Лимит устройств', sortField: 'device_limit', field: 'device_limit'},
                {name: 'Активные устройства',
                    field: ({devices}) => devices.length},
                {name: '', field: field => <MoreMenuButton key={field.email} menu={[
                    {primary: "Редактировать", icon: <IconEdit fontSize="small" />, onClick: () => setEditingItem(field)}
                ]}/>}
            ]}
        />

    </Card>

}
