/**
 *  Orange theme is a fork of default material-ui LightTheme,
 *  made especially for Vulners.com !!!
 */
import React from "react";
// import {fade} from "@material-ui/core/utils/colorManipulator";
import deepOrange from '@material-ui/core/colors/deepOrange';
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const deepOrange200 = deepOrange['200'];
const deepOrange500 = deepOrange['500'];
const deepOrange700 = deepOrange['700'];
const deepOrange100 = deepOrange['100'];
const darkBlack = common.darkBlack;
const white = common.white;
const grey300 = grey['300'];
const fullBlack = common.fullBlack;

const MagicTheme = createMuiTheme({
    "breakpoints": {
        "keys": ["xs", "sm", "md", "lg", "xl"],
        "values": {"xs": 0, "sm": 600, "md": 960, "lg": 1280, "xl": 1920}
    },
    "direction": "ltr",
    "mixins": {
        "toolbar": {
            "minHeight": 56,
            "@media (min-width:0px) and (orientation: landscape)": {"minHeight": 48},
            "@media (min-width:600px)": {"minHeight": 64}
        }
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 12
            }
        },
    },
    "palette": {
        "common": {"black": "#000", "white": "#fff"},
        "type": "light",
        "default": {
            "main": "#1d1e3d",
            "light": "#1d1e3d",
            "dark": "#1d1e3d",
            "contrastText": "#fff"
        },
        "primary": {
            "main": "#2358ff",
            "light": "#2358ff",
            "dark": "#2358ff",
            "contrastText": "#fff"
        },
        "secondary": {
            "main": "#fafafa",
            "light": "#fff",
            "dark": "#fafafa",
            "contrastText": "#fff"
        },
        "info": {
            "main": "#0000008a",
            "light": "#0000008a",
            "dark": "#0000008a",
            "contrastText": "#fff"
        },
        "error": {"light": "#e57373", "main": "#d32f2f", "dark": "#d32f2f", "contrastText": "#fff"}
    }
});

export default MagicTheme
