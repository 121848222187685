import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconMore from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import IconBlock from '@material-ui/icons/Block';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function MoreMenuButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (e, callback) => {
        callback(e);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <IconMore />
            </IconButton>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.menu.map((i) => (
                    <MenuItem key={props.key + i} onClick={(e) => handleSelect(e, i.onClick)}>
                        <ListItemIcon>{i.icon}</ListItemIcon>
                        <ListItemText
                            primary={i.primary}
                            secondary={i.secondary}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
