import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import API from "../../API";
import {AppBar, Tab, TableBody, Tabs} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 16
    }

}));



export default () => {
    const classes = useStyles()
    const history = useHistory()

    const [data, setData] = useState([])

    return <div className={classes.root}>

        <Box>Under Construction...</Box>

    </div>

}
