import React, {createRef, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from "../components/Copyright";
import Captcha from "../components/Captcha";
import API from "../API";
import Form from "../components/Form";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const recaptchaRef = createRef()

const RestorePassword = ({userStore}) => {
    const classes = useStyles()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [captcha, setCaptcha] = useState('')

    const [error, setError] = useState('')

    const restorePassword = () => {
        API.fetch(API.AUTH_RECOVER_PASSWORD, {email, captcha})
            .then(r => {
                console.log('[RESTORE_PASSWORD]', r)
            })
            .catch(e => {
                setError(e)
                recaptchaRef.current.reset()
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Восстановить доступ
                </Typography>
                <Form className={classes.form} noValidate onSubmit={restorePassword}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setEmail(e.target.value)}
                    />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcQRoMaAAAAALwiHqTZ0gHlJFcsMOH85Wl_PJLa"
                        onChange={setCaptcha}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={restorePassword}
                    >
                        Восстановить
                    </Button>
                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Link href="sign-in" variant="body2" onClick={e => {
                                e.preventDefault()
                                history.push('/sign-in')
                            }}>
                                Вход в аккаунт
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default inject('userStore')(observer(RestorePassword))