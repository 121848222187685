import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import API from "../../API";
import {AppBar, Tab, TableBody, Tabs} from "@material-ui/core";
import Users from "./Users";
import Devices from "./Devices";

const useStyles = makeStyles((theme) => ({
    root: {

    }

}));

const TabPanel = ({ children, value, index }) => value === index && children;

export default () => {
    const classes = useStyles()
    const history = useHistory()

    const [tab, setTab] = useState(0)

    return <Box p={4} className={classes.root}>

        <AppBar
            position="static"
            color="secondary"
            className={classes.appBar}
        >
            <Tabs value={tab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(e, newValue) => setTab(newValue)}>
                <Tab label="Пользователи"/>
                <Tab label="Устройства"/>
            </Tabs>
        </AppBar>

        <TabPanel value={tab} index={0}>
            <Users/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <Devices/>
        </TabPanel>

    </Box>

}
