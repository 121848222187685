import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {
    Card,
    CardActions,
    CardContent,
    Chip, IconButton,
    List,
    ListItem, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Menu, MenuItem, TextField,
    Typography
} from "@material-ui/core";
import {
    Delete,
    Edit,
    FiberManualRecord,
    FiberManualRecordOutlined, Memory,
    MoreVert, NoteSharp, PictureInPicture,
    PictureInPictureSharp,
    Save, ScreenShare
} from "@material-ui/icons";
import API from "../../API";
import AlertDialog from "../../components/Alert";


const useStyles = makeStyles((theme) => ({
    card: {
        margin: 8,
        minWidth: 450
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '16px 32px'
    },
    headerName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerActions: {
        display: 'flex',
        flexDirection: 'row',
    }
}));


const Device = ({name, ip, id, osVersion, envVersion, online, lastPing, onDelete, onEdit}) => {
    const classes = useStyles()
    const history = useHistory()

    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const [showEditing, setShowEditing] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const [newName, setNewName] = useState(name)

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget)
    const handleMenuClose = () => setAnchorEl(null)

    const deleteDevice = () => API.fetch(API.DEVICE_DELETE, {id})
        .then(r => {
            r.status === 'OK' ? onDelete() : console.error('[DEVICE_DELETE]', r)
        })

    const editDevice = () => API.fetch(API.DEVICE_EDIT, {id, name: newName})
        .then(() => {
            setEditingName(false)
            onEdit()
        })

    return <Card className={classes.card}
                 onMouseEnter={() => setShowEditing(true)}
                 onMouseLeave={() => setShowEditing(false)}>
        <div className={classes.header}>

            {editingName ?
                <div className={classes.headerName}>
                    <TextField defaultValue={newName} fullWidth onChange={e => setNewName(e.target.value)}/>
                    <IconButton onClick={editDevice}><Save/></IconButton>
                </div> :
                <div className={classes.headerName}>
                    <Typography variant='h6' component='div'>
                        {name}
                    </Typography>
                    {showEditing && <IconButton onClick={() => setEditingName(true)}><Edit/></IconButton>}
                </div>
            }
            <div>
                <Chip
                    icon={online ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
                    label={online ? "Активен" : "Нет связи"}
                    clickable
                    color={online ? 'primary' : 'default'}
                    variant="outlined"
                />
                <IconButton onClick={handleMenuClick}>
                    <MoreVert/>
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted
                      open={menuOpen} onClose={handleMenuClose}>
                    <MenuItem onClick={() => {}}>
                        <ListItemIcon>
                            <ScreenShare/>
                        </ListItemIcon>
                        Снимок Экрана
                    </MenuItem>
                    <MenuItem onClick={() => {}}>
                        <ListItemIcon>
                            <Memory/>
                        </ListItemIcon>
                        Список Процессов
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteAlertOpen(true)}>
                        <ListItemIcon>
                            <Delete/>
                        </ListItemIcon>
                        Удалить устройство
                    </MenuItem>
                </Menu>
            </div>
        </div>

        <CardContent className={classes.cardContent}>

            <List dense>

                <ListItem>
                    <ListItemText>IP адрес</ListItemText>
                    <ListItemSecondaryAction>
                        <ListItemText>{ip}</ListItemText>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemText>Последний Ping</ListItemText>
                    <ListItemSecondaryAction>
                        <ListItemText>{lastPing}</ListItemText>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemText>Версия оболочки</ListItemText>
                    <ListItemSecondaryAction>
                        <ListItemText>{envVersion}</ListItemText>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemText>Версия ОС</ListItemText>
                    <ListItemSecondaryAction>
                        <ListItemText>{osVersion}</ListItemText>
                    </ListItemSecondaryAction>
                </ListItem>

            </List>


        </CardContent>
        <CardActions>

        </CardActions>

        {deleteAlertOpen &&
            <AlertDialog title={`Удалить устройство - ${name}?`}
                         text="Вы действительно хотите удалить устройство?
                         Через некоторое время после удаления оно перестанет функционировать!"
                         onClose={() => setDeleteAlertOpen(false)}
                         onSubmit={() => {
                             setDeleteAlertOpen(false)
                             setAnchorEl(null)
                             deleteDevice()
                         }}
            />}
    </Card>
}

export default Device